.panel {
    background-color: transparent;
    box-shadow: none;
}

.btn-group,
.list-group,
.list-group-item {
    width: 100%;
}

.btn-group {
    margin: 0;
    padding: 0;
    border: 1px solid #ddd;
    background: #eee;
}

.btn-group .btn {
    display: block;
    float: left;
    padding: 10px 15px;
    margin: 0 !important;
    border-right: 1px solid #ddd;
}

.btn-group .btn:last-child {
    border-width: 0px;
}

.is-checked {
    font-weight: 600;
}

.list-group {
    border-width: 0 !important;
    border-radius: 0 !important;
    box-shadow: none;
}

.list-group-item {
    padding: 0;
    margin: 0;
    border-width: 0px 1px 0;
    border-radius: 0 !important;
    background-color: #fff;
}

.list-group-item span {
    display: block;
    float: left;
    padding: 10px 15px;
    border-color: #ddd;
    border-style: solid;
    border-width: 0 1px 1px 0;
    box-sizing: border-box;
}

.list-group-item span:last-child {
    border-right-width: 0;
}

.id {
    /*   min-width: 100px; */
    width: 10%;
}

.name {
    /*   min-width: 120px; */
    width: 30%;
}

.org {
    /*   min-width: 100px; */
    width: 20%;
}

.city {
    /*   min-width: 100px; */
    width: 20%;
}

.type {
    /*   min-width: 100px; */
    width: 20%;
}
